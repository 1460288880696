export const marketplaceData = [
  {
    title: "Tropical Skunk",
    cross: "(Island Sweet Skunk x GDP)",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8.00 per seed",
    price: 8,
    image: "./assets/Tropical_Skunk/tropical-skunk-1.jpeg",
    features: [
      "22-25% thc",
      "50-50 Inidica/Sativa",
      "Heavy Yield",
      "10-12 weeks flower time",
    ],
    id: 1,
    qty: 0,
  },
  {
    title: "Afghani Lemons",
    cross: "(Afghan Kush x Super Lemon Haze)",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 3,
    qty: 0,
    features: [
      "24-26% THC; 3% CBN; 1% CBD",
      "Sativa Dominant 70/30",
      "Relief of Chronic Muscle aches, Anxiety, Depression, Fatigue, Stimulates Creativity and Arousal",
      "11-12 weeks flower time",
    ],
    image: "./assets/Afghani_Lemons/afghani-lemons-0.jpeg",
  },
  {
    title: "Yummies Hybrid",
    cross: "(Sour Diesal x Sage Diesal)",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8.00 per seed",
    price: 8,
    image: "./assets/Yummies/yummies-2.jpeg",
    features: [
      "24-28% thc",
      "Sativa Dominant",
      "Heavy Yield",
      "9-10 weeks flower time",
    ],
    id: 2,
    qty: 0,
  },

 
 

  {
    title: "Granddaddy Urkle",
    cross: "(GDP x Purple Urkle)",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 9,
    image: "./assets/GDU/gdu-1.jpeg",
    productNumber: "0005fp",
    features: [
      "22-28% thc",
      "Indica Dominant",
      "Heavy Yield",
      "9 weeks flower time",
    ],

    qty: 0,
  },

  {
    title: "Granddaddy Purple",
    cross: null,
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8 per seed",
    price: 8,

    image: "./assets/GDP/gdp-0.jpeg",
    id: 11,
    features: [
      "25-28% thc",
      "80% Indica",
      "Heavy Yield",
      "9 weeks flower time",
    ],
    qty: 0,
  },
  {
    title: "Alaskan Shield Maiden",
    cross: "(ATF x Shield Maiden)",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8 per seed",
    price: 8,

    image: "./assets/General/seed.jpg",
    id: 60,
    features: [
      "22-24% thc",
      "12% CBD",
      "90/10 Sativa",
      "Works well for mood enhancement, PTSD, Depression, Anxiety, energy booster",
    ],
    qty: 0,
  },
  {
    title: "ATF",
    cross: null,
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8 per seed",
    price: 8,
    image: "./assets/General/seed.jpg",
    id: 61,
    features: [
      "19-21% thc",
      "70/30 Sativa",
      "Creeper high with lasting relaxation effects",
    ],
    qty: 0,
  },
  {
    title: "Tropical Thunder",
    cross: "(Tropical Skunk x ATF)",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8 per seed",
    price: 8,
    image: "./assets/General/seed.jpg",
    id: 62,
    features: [
      "20-22% thc",
      "100% Sativa",
      "Perfect for outdoor growers in dry arid climates.",
    ],
    qty: 0,
  },
  {
    title: "Shield Maiden",
    cross: "(Charlotte's Web x Yummies)",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8 per seed",
    price: 8,
    image: "./assets/Shield_Maiden/shield-maiden-2.jpeg",
    id: 68,
    features: [
      "22-24% thc",
      "80% Sativa",
      "1:1 THC:CBD Terpene Healing Machine",
    ],
    qty: 0,
  },
  {
    title: "OG Kush #23",
    cross: null,
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8 per seed",
    price: 8,
    image: "./assets/General/seed.jpg",
    id: 69,
    features: [
      "thc: 24-26%  1% CBN",
      "Sativa dominant 60/40",
    ],
    qty: 0,
  },
 
  
  {
    title: "King Gelato",
    cross: "(Gelato x GG#4)",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    image: "./assets/Purple_Gelato/purple-gelato-4.jpeg",

    id: 14,
    qty: 0,
    features: [
      "23-26% thc",
      "90% Sativa",
      "Heavy Yield",
      "110 days seed to harvest",
    ],
  },
  {
    title: "SkyWalker",
    cross: "(Skywalker OG x Ruderalis)",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    image: "./assets/Skywalker/skywalker-2.jpeg",

    id: 15,
    qty: 0,

    features: [
      "22-26% thc",
      "Sativa Dominant",
      "Heavy Yield",
      "100 days seed to harvest",
    ],
  },
  {
    title: "King Glue",
    cross: "(King Gelato X GG#4)",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    image: "./assets/King_Glue/king-glue-0.jpeg",

    id: 16,
    qty: 0,

    features: [
      "24-26% thc",
      "Average Yield",
      "90% Indica",
      "100 days seed to harvest",
    ],
  },

  {
    title: "Blue Gelato",
    cross: "(Blue Dream x Gelato)",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 17,
    qty: 0,

    features: [
      "23-25% thc",
      "Average Yield",
      "Sativa Dominant",
      "100 days seed to harvest",
    ],
    image: "./assets/Blue_Gelato/blue-gelato-0.jpeg",
  },
  {
    title: "Lemon Haze",
    cross: "(Super Lemon Haze x Ruderalis)",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    id: 18,
    price: 8,
    qty: 0,
    title2: "feminized auto",

    features: [
      "19-23% thc",
      "Average Yield",
      "Sativa Dominant 80/20",
      "90 days seed to harvest",
    ],
    image: "./assets/Lemon_Haze/lemon-haze-0.jpeg",
  },
  {
    title: "Strawberry Cheesecake",
    cross: "(Strawberry Kush x Cheesecake)",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 19,

    qty: 0,
    features: [
      "22-24% thc",
      "Average Yield",
      "Indica Dominant 70/30",
      "80 days seed to harvest",
    ],
    image: "./assets/Strawberry_Cheesecake/strawberry-cheesecake-1.jpeg",
  },
  {
    title: "Wedding Cheesecake",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 20,
    qty: 0,

    features: [
      "25-27% thc",
      "Average Yield",
      "Hybrid 65/35 Sativa",
      "90 days seed to harvest",
    ],
    image: "./assets/Wedding_Cheesecake/wedding-cheesecake-1.jpeg",
  },
  {
    title: "Runtz",
    cross: "(Runtz x Ruderalis)",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 21,
    qty: 0,

    features: [
      "26-29% thc",
      "Average Yield",
      "50/50 Hybrid",
      "75-80 days seed to harvest",
    ],
    image: "./assets/Runtz/runtz-0.jpeg",
  },
  {
    title: "Blue Dream",
    cross: "(Blue Dream x Ruderalis)",

    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 22,

    qty: 0,
    features: [
      "26-29% thc",
      "Heavy Yield",
      "Sativa Dominant 80/20",
      "90 days seed to harvest",
    ],
    image: "./assets/Blue_Dream/blue-dream-0.jpeg",
  },
  
  {
    title: "Blue Lemonade Punch",
    cross: "(Blue Dream x Punchout)",

    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 71,

    qty: 0,
    features: [
      "24-26% thc",
      "70/30 Sativa ",
      "80 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "U.K. Cheese",
    cross: null,

    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 72,

    qty: 0,
    features: [
      "22-24% thc",
      "75/25 Indica ",
      "80 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Apple Fritter",
    cross: null,

    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 73,

    qty: 0,
    features: [
      "22-24% thc",
      "50/50 Hybrid",
      "75 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Apple Fritter #3",
    cross: null,

    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 74,

    qty: 0,
    features: [
      "22-24% thc",
      "50/50 Hybrid",
      "90 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Strawberry Apple Fritter",
    cross: "(Strawberry Cheesecake x Apple Fritter)",

    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 75,

    qty: 0,
    features: [
      "23-25% thc",
      "60/40 Indica",
      "75 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Sour Apple Fritter",
    cross: "(Sour Diesel x Apple Fritter)",

    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 76,

    qty: 0,
    features: [
      "22-24% thc",
      "65/35 Sativa",
      "80 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Blu Cheese",
    cross: "(Blue Dream x UK Cheese)",

    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 200,

    qty: 0,
    features: [
      "22-24% THC",
      "50/50 Hybrid ",
      "80 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "NYPD",
    cross: "",

    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 100,

    qty: 0,
    features: [
      "21-23% THC",
      "70/30 Sativa",
      "75-80 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "NYPD #14",
    cross: "",

    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 101,

    qty: 0,
    features: [
      "22-24% THC",
      "70/30 Sativa",
      "75-80 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "NY Lemonade",
    cross: "(NYPD x Lemon Haze)",

    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 102,

    qty: 0,
    features: [
      "23-25% THC",
      "80/20 Sativa",
      "75-80 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  
  {
    title: "BX Zkittkes aka Bronx Zkittles",
    cross: "(NYPD x Lemon Zkittles)",

    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 104,

    qty: 0,
    features: [
      "22-24% THC",
      "70/30 Sativa",
      "75-80 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "NY Dream",
    cross: "(NYPD x Blue dream)",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 105,
    qty: 0,
    features: [
      "24-26% THC",
      "80/20 Sativa",
      "75 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Lost Jedi",
    cross: "(NYPD x Skywalker)",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 106,
    qty: 0,
    features: [
      "21-23% THC",
      "70/30 Sativa",
      "80 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Lemon Cheesecake",
    cross: "(Lemon Zkittles x Wedding Cheesecake)",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 107,
    qty: 0,
    features: [
      "20-22% THC",
      "60/40 Sativa",
      "75 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Italian Cheesecake",
    cross: "(NYPD x Wedding Cheesecake)",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 108,
    qty: 0,
    features: [
      "22-24% THC",
      "65/35 Sativa",
      "75-80 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Sour Diesal",
    cross: "",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 109,
    qty: 0,
    features: [
      "22-24% THC",
      "60/40 Sativa",
      "80 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Lemon Zkittles",
    cross: "",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 109,
    qty: 0,
    features: [
      "22-24% THC",
      "60/40 Sativa",
      "75-80 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Corner Store",
    cross: "(NYPD x Green Crack)",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 110,
    qty: 0,
    features: [
      "22-24% THC",
      "70/30 Sativa",
      "85 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  
  {
    title: "Burnt Marshmallow",
    cross: "(Black Skunk x Stay Puft)",

    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 23,
    qty: 0,

    features: [
      "28% thc",
      "Average Yield",
      "70/30 Indica",
      "10 weeks flower time",
    ],
    image: "./assets/General/seed.jpg",
  },

  {
    title: "Granddaddy Skunk",
    strainClass: "Regular Photo",
    cross: "(Black Skunk x Granddaddy Urkle)",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 25,
    qty: 0,

    features: [
      "22% thc",
      "Average Yield",
      "75/25 Indica",
      "10 weeks flower time",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Hindu Kush",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 26,
    qty: 0,

    features: [
      "22-27% thc",
      "Average Yield",
      "100% Indica",
      "8 weeks flower time",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Strawberry Cough",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 27,
    qty: 0,

    features: [
      "22-24% thc",
      "Average Yield",
      "80/20 Indica",
      "11 weeks flower time",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Durban Poison",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 28,
    qty: 0,

    features: [
      "24% thc",
      "Average Yield",
      "100% Sativa",
      "11-12 weeks flower time",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Northern Lights",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 29,
    qty: 0,

    features: [
      "18-22% thc",
      "Average Yield",
      "100% Indica",
      "8 weeks flower time",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Black Shield",
    cross: "(Black Skunk x Shield Maiden)",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 30,
    qty: 0,

    features: ["24% thc", "20% cbd", "60/40 Indica", "10 weeks flower time"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Black Kush",
    cross: "(Black Skunk x OG Kush)",

    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 31,
    qty: 0,

    features: ["thc: 22-24% cbn: 1%", "60/40 Indica", "10 weeks flower time"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Colombian Gold",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 32,
    qty: 0,
    features: ["19% thc", "12 weeks flower time", "75/25 Sativa"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Black Rifle",
    cross: "(Black Skunk x OG Kush)",

    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 33,
    qty: 0,
    features: ["22-23% thc", "9 weeeks flower time", "50/50 Hybrid"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Pinapple Express",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 34,
    qty: 0,
    features: ["25% thc", "9 weeks flower time", "60/40 Sativa"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Mayan Calendar",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 35,
    qty: 0,
    features: ["25% thc", "13 weeks flower time", "90/10 Sativa"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Maui Wowie",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 36,
    qty: 0,
    features: ["26-28% thc", "11 weeks flower time", "80/20 Sativa"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Lambs Breath aka Lambs Bread",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 37,
    qty: 0,
    features: ["22% thc", "10 weeks flower time", "70/30 Sativa"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Granddaddy Purple",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 38,
    qty: 0,
    features: ["25-27% thc", "9 weeks flwoer time", "70/30 Indica"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Alaskan Thunder Skunk",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 39,
    qty: 0,
    features: ["22% thc", "10 weeks flower time", "60/40 Sativa"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Blueberry Kush",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 40,
    qty: 0,
    features: ["24% thc", "10 weeks flower time", "100% Indica"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Afghan Kush",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 41,
    qty: 0,
    features: [
      "21% thc",
      "6% cbd",
      "1% cbn",
      "100% Indica",
      "8 weeks flower time",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Grape Ape",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 42,
    qty: 0,
    features: ["18-21% thc", "8 weeks flower time", "90/10 Indica"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Blue Dream",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 43,
    qty: 0,
    features: [
      "20-24% thc",
      "2% cbd",
      "1% cbn",
      "Sativa Dominant 60/40",
      "10 weeks flower time",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Amnesia Haze",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 44,
    qty: 0,
    features: [
      "20-22% thc",
      "1% cbd",
      "11 weeks flower time",
      "Sativa Dominant 80/20",
    ],
    image: "./assets/General/seed.jpg",
  },

  {
    title: "Girl Scout Cookies",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 46,

    qty: 0,
    features: [
      "28% thc",
      "1% cbd",
      "1% cbn",
      "60/40Indica",
      "9 weeks flower time",
    ],
    image: "./assets/General/seed.jpg",
  },

  {
    title: "Green Crack",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 48,

    qty: 0,
    features: ["22-25% thc", "65/35 Sativa", "8 weeks flower time"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "OG Kush #23",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 49,

    qty: 0,
    features: ["24-26% thc", "1% cbn", "9 weeks flower time", "60/40 Sativa"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Skywalker OG",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 50,

    qty: 0,
    features: ["24-25% thc", "2% cbn", "85/15 Indica", "9 weeks flower time"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Purple Punch",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 51,

    qty: 0,
    features: ["18-20% thc", "1% cbd", "80/20 Indica", "9 weeks flower time"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Super Silver Haze",
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 52,

    qty: 0,
    features: ["18-22% thc", "80/20 Sativa", "11 weeks flower time"],
    image: "./assets/General/seed.jpg",
  },
];
const soldOutStrains = [
  // {
  //   title: "Tropical Shield Maiden",
  //   cross: "(Charlotte's Web x Yummies)",
  //   strainClass: "Feminized Photoperiod",
  //   displayPrice: "$8.00 per seed",
  //   price: 8,

  //   image: "./assets/Shield_Maiden/shield-maiden-0.jpeg",
  //   productNumber: "0005fp",
  //   features: [
  //     "22-24% THC; 22-24% CBD; 3% CBN",
  //     "80/20 Sativa",
  //     "Relief of Seizures, Chemotherapy Treatment, Chronic pain, Chronic/ Severe Migraine, Fibromyalgia, Gout, Multiple Sclerosis, Inflammation, PTSD",
  //     "9-10 weeks flower time",
  //   ],
  //   id: 10,
  //   qty: 0,
  // },
  
  {
    title: "Lemonade Punch",
    cross: "(Lemon Haze x Punchout)",

    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 70,

    qty: 0,
    features: [
      "24-26% thc",
      "50/50 Hybrid",
      "80 days seed to harvest",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Green Crack",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    image: "./assets/Green_Crack/greencrack-3.jpeg",
    productNumber: "0002fa",

    id: 12,
    qty: 0,
    features: [
      "25% thc",
      "Sativa Dominant",
      "Average Yield",
      "100 days seed to harvest",
    ],
  },
  {
    title: "Granddaddy Diesal",
    cross: "(GDU x Shield Maiden)",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 7,
    qty: 0,
    features: [
      "24-26% THC 15-16% CBD 1% CBN",
      "65/35 Sativa",
      "Relief of Muscle Pain, Joint Inflammation, Gout, Multiple Sclerosis, Chronic migraines and Headaches",
      "9-10 weeks flower time",
    ],
    image: "./assets/Granddaddy_Diesal/granddaddy-diesal-1.jpeg",
  },
  {
    title: "Chem Dawg #4",
    cross: null,
    strainClass: "Regular Photo",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 24,
    qty: 0,

    features: [
      "20-23% thc",
      "Average Yield",
      "50/50 Hybrid",
      "11 weeks flower time",
    ],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Stay Puft",
    cross: "(Marshmallow OG x Grape Gas)",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$10.00 per seed",
    price: 8,
    image: "./assets/Stay_Puft/stay-puft-0.jpeg",
    features: [
      "22-28% thc",
      "70% Indica",
      "Average Yield",
      "8-9 weeks flower time",
    ],
    productNumber: "0004fp",
    id: 4,
    qty: 0,
  },

  {
    title: "Gorilla Glue #4",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 47,

    qty: 0,
    features: ["30% thc", "9 weeks flower time", "60/40Indica"],
    image: "./assets/General/seed.jpg",
  },
  {
    title: "Gorilla Glue #4",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    image: "./assets/GG4auto/gg4-1.jpeg",

    id: 53,
    qty: 0,

    features: [
      "23-26% thc",
      "Indica Dominant",
      "Moderate Yield",
      "100 days seed to harvest",
    ],
  },
  {
    title: "Frozen Grapes",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8.00 per seed",
    price: 8,
    image: "./assets/Frozen_Grapes/frozen-grapes-0.jpeg",

    features: ["22-28% thc", "70% Indica", "Average Yield"],
    id: 0,
    qty: 0,
  },
  {
    title: "Purple Urkle",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$4.20 per seed",
    price: 8.2,
    image: "./assets/Purple_Urkle/purple-urkle-0.jpeg",

    features: ["18-22% thc", "70% Indica", "Moderate Yield"],
    productNumber: "0007fp",
    id: 7,
    qty: 0,
  },
  {
    title: "Stay Strong",
    cross: "(Stay Puft x Shield Maiden)",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 5,
    qty: 0,
    features: [
      "30-32% THC; 16% CBD",
      "60/40 Indica",
      "Relief of Insomnia, PTSD, Stress, Anxiety, Pms, chronic muscle ache, joint pain",
      "9-10 weeks flower time",
    ],
    image: "./assets/Stay_Strong/stay-strong-0.jpeg",
  },
  {
    title: "Tropical Thunder",
    cross: "(Tropical Skunk x Shield Maiden)",
    strainClass: "Feminized Photoperiod",
    displayPrice: "$8.00 per seed",
    price: 8,
    id: 6,
    qty: 0,
    features: [
      "24-26% THC 10-12% CBD",
      "70/30 Sativa",
      "Relief of Bi-Polar, Depression, Mood Swings, Loss of Appetite, Joint Pain, Headache, Migraine -- Not Recommended for those sensitive to high sativa strains ",
      "9-10 weeks flower time",
    ],
    image: "./assets/Tropical_Shield_Maiden/Tropical-shield-maiden-0.jpeg",
  },
  {
    title: "Purple Silverback",
    cross: "(GG#4 Purple Ruderalis)",
    strainClass: "Feminized Autoflower",
    displayPrice: "$8.00 per seed",
    price: 8,
    image: "./assets/Purple_Silverback/purple-silverback-4.jpeg",
    productNumber: "0003fa",

    id: 13,
    qty: 0,
    features: ["Indica Dominant", "Moderate Yield", "100 days seed to harvest"],
  }
];

// {
//   title: "ATF II",
//   cross: "(A.T.F. x Shield Maiden)",
//   strainClass: "Feminized Photoperiod",
//   displayPrice: "$8.00 per seed",
//   price: 8,
//   id: 8,

//   qty: 0,
//   features: [
//     "22-23% THC -- 15-16% CBD",
//     "70/30 Sativa",
//     "Relief of Fatigue, Nausea, Migraines, Depression, ADD, ADHD, loss of appetite ",
//     "9-10 weeks flower time",
//   ],
//   image: "./assets/ATF_2/ATF2-0.jpeg",
// },

